import type { SelectProps } from 'antd';
import { Button, Form, Popconfirm, Select, Table } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '@/i18n';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const operations: SelectProps['options'] = [
  { label: i18n.t('operations.manage'), value: 'manage' },
  { label: i18n.t('operations.create'), value: 'create' },
  { label: i18n.t('operations.get'), value: 'get' },
  { label: i18n.t('operations.find'), value: 'find' },
  { label: i18n.t('operations.patch'), value: 'patch' },
  { label: i18n.t('operations.delete'), value: 'delete' },
];

const services: SelectProps['options'] = [
  { label: i18n.t('services.all'), value: 'all' },
  { label: i18n.t('services.agents'), value: 'agents' },
  { label: i18n.t('services.apiKeys'), value: 'api-keys' },
  { label: i18n.t('services.attributes'), value: 'attributes' },
  { label: i18n.t('services.categories'), value: 'categories' },
  { label: i18n.t('services.configs'), value: 'configs' },
  { label: i18n.t('services.contact'), value: 'contact' },
  { label: i18n.t('services.currencies'), value: 'currencies' },
  { label: i18n.t('services.emailLogs'), value: 'email-logs' },
  { label: i18n.t('services.fields'), value: 'fields' },
  { label: i18n.t('services.forgot'), value: 'forgot' },
  { label: i18n.t('services.labels'), value: 'labels' },
  { label: i18n.t('services.leads'), value: 'leads' },
  { label: i18n.t('services.mail'), value: 'mail' },
  { label: i18n.t('services.metaCategories'), value: 'meta-categories' },
  { label: i18n.t('services.multimedia'), value: 'multimedia' },
  { label: i18n.t('services.pages'), value: 'pages' },
  { label: i18n.t('services.properties'), value: 'properties' },
  { label: i18n.t('services.roles'), value: 'roles' },
  { label: i18n.t('services.search'), value: 'search' },
  { label: i18n.t('services.states'), value: 'states' },
  { label: i18n.t('services.types'), value: 'types' },
  { label: i18n.t('services.units'), value: 'units' },
  { label: i18n.t('services.users'), value: 'users' },
  { label: i18n.t('services.utils'), value: 'utils' },
  { label: i18n.t('services.verify'), value: 'verify' },
];

interface Item {
  key: number;
  actions: string[];
  subject: string[];
}

interface EditableRowProps {
  index: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const form = useContext(EditableContext)!;
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setEditing(false);
    };
  }, [record]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (error) {
      /* empty */
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          allowClear
          mode="multiple"
          onChange={save}
          options={dataIndex === 'actions' ? operations : services}
          placeholder={
            dataIndex === 'actions' ? t('roles.selectActions') : t('roles.selectServices')
          }
          style={{ width: '100%' }}
        />
      </Form.Item>
    ) : (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        style={{ paddingTop: '5px', paddingBottom: '5px' }}
        onClick={toggleEdit}
        onKeyDown={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

export interface DataType {
  key: React.Key;
  actions: string[];
  subject: string[];
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface PermissionsFormProps {
  data: DataType[];
  setData: (data: DataType[]) => void;
}

interface RenderObject {
  key?: React.Key;
}

const PermissionsForm: React.FC<PermissionsFormProps> = ({ data = [], setData }) => {
  const [count, setCount] = useState(data?.length);
  const { t } = useTranslation();

  const getCorrespondingVal = (value: string[], dataIndex: string) => {
    const arrNewValuesTemp: string[] = [];
    const tempOperationArr = dataIndex === 'actions' ? operations : services;
    if (value) {
      value.map((val: string) => {
        const newVal = tempOperationArr.filter((operation) => operation.value === val);
        arrNewValuesTemp.push(newVal.length ? (newVal[0].label as string) : val);
      });
    }

    return arrNewValuesTemp;
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      dataIndex: 'actions',
      editable: true,
      title: t('roles.actions'),
      width: '40%',
      render: (value) => <p>{getCorrespondingVal(value, 'actions')?.join(', ')}</p>,
    },
    {
      dataIndex: 'subject',
      editable: true,
      title: t('roles.services'),
      width: '40%',
      render: (value) => <p>{getCorrespondingVal(value, 'subject')?.join(', ')}</p>,
    },
    {
      dataIndex: 'operation',
      title: '',
      render: (_, record: RenderObject) => (
        <Popconfirm
          title={t('roles.permissionsDelete')}
          onConfirm={() => handleDelete(record.key)}
        >
          <a>{t('general.delete')}</a>
        </Popconfirm>
      ),
    },
  ];

  const handleDelete = (key: React.Key | undefined) => {
    setData(data.filter((item) => item.key !== key));
  };

  const handleAdd = () => {
    const newData: DataType = {
      key: count + 1,
      actions: [],
      subject: [],
    };

    setData([...data, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: DataType) => {
    const newData = [...data];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    newData.splice(index, 1, { ...item, ...row });
    setData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: DataType) => ({
        dataIndex: col.dataIndex,
        editable: col.editable,
        handleSave,
        record,
      }),
    };
  });

  return (
    <>
      <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
        {t('roles.addRow')}
      </Button>
      <Table
        className="custom-table"
        bordered
        columns={columns as ColumnTypes}
        components={components}
        dataSource={data}
        pagination={false}
      />
    </>
  );
};

export default PermissionsForm;
