import React from 'react';
import { Form, FormInstance, Input, Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Roles } from '@/interfaces/roles.interface';
import { validateSlugField } from '@/utils/validations';

export interface RoleFormProps {
  formRef: FormInstance<Roles>;
  onSubmit?: (values: Roles) => Promise<void>;
}

const RoleForm: React.FC<RoleFormProps> = ({ onSubmit, formRef }) => {
  const { t } = useTranslation();

  return (
    <Form
      form={formRef}
      name="role-form"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmit}
    >
      <Row gutter={[10, 10]}>
        <Col span={10}>
          <Form.Item
            label={t('roles.name')}
            name="name"
            rules={[
              {
                max: 30,
                min: 5,
                required: true,
                type: 'string',
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t('roles.group')}
            name="group"
            rules={[
              {
                max: 30,
                min: 4,
                required: true,
                type: 'string',
                whitespace: true,
              },
              {
                validator: validateSlugField,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={t('general.status')}
            name="status"
            initialValue={1}
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: '100%' }}
              options={[
                {
                  label: t('general.active'),
                  value: 1,
                },
                {
                  label: t('general.inactive'),
                  value: 0,
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default RoleForm;
