import { Button, Table, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { parseFilter } from '@/utils/filter';
import { useTranslation } from 'react-i18next';
import { Roles, RawRoles } from '@/interfaces/roles.interface';
import { FilterByRegex, FilterByStatus, FilterByIcon } from '../utils/CommonFilters';

export interface RolesTableProps {
  data?: RawRoles[];
  total?: number;
  current?: number;
  loading?: boolean;
  onFilter?: (value: any) => any;
  onEdit?: (data: Roles) => void;
  onView?: (data: Roles) => void;
  onDelete?: (id: string) => void;
}

const RolesTable = ({
  data,
  total,
  loading,
  onFilter,
  onDelete,
  onEdit,
}: RolesTableProps) => {
  const { t } = useTranslation();

  const Columns: ColumnsType<RawRoles> = [
    {
      title: t('roles.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      filterDropdown: FilterByRegex,
      filterIcon: FilterByIcon,
    },
    {
      title: t('roles.group'),
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: t('general.active'),
          value: '1',
        },
        {
          text: t('general.inactive'),
          value: '0',
        },
      ],
      filterDropdown: FilterByStatus([
        {
          label: t('general.active'),
          value: '1',
        },
        {
          label: t('general.inactive'),
          value: '0',
        },
      ]),
      render: (status: number) =>
        status === 1 ? t('general.active') : t('general.inactive'),
    },
    {
      title: t('general.actions'),
      key: '_id',
      render: (text: string, record: RawRoles) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit?.(record)}>
            {t('general.edit')}
          </Button>
          <Button type="link" onClick={() => onDelete?.(record._id)}>
            {t('general.delete')}
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnChange = (pagination: any = {}, filters: any = {}, sorter: any) => {
    if (onFilter) {
      onFilter((value: Record<string, string>) => ({
        ...parseFilter(filters, value, sorter),
        $skip: pagination.current ? (pagination.current - 1) * 10 : 0,
      }));
    }
  };

  return (
    <Table
      className="custom-table"
      dataSource={data}
      columns={Columns}
      loading={loading}
      onChange={handleOnChange}
      rowKey={(e) => e._id}
      pagination={{
        total,
        defaultPageSize: 10,
        showTotal: (total) => t('general.total', { total }),
      }}
    />
  );
};

export default RolesTable;
