import React, { useEffect, useState } from 'react';
import { Modal, Form, message } from 'antd';
import RoleForm from '../forms/RoleForm';
import PermissionsForm, { DataType } from '../forms/PermissionsForm';
// Hooks
import { useTranslation } from 'react-i18next';
// Interfaces
import { Roles, Permission } from '@/interfaces/roles.interface';
// Services
import APIService from '@/services/API';

export interface RolesModalProps {
  visible: boolean;
  edit?: boolean;
  data?: Record<string, string | number>;
  onCancel?: () => void;
  onOk?: () => void;
}
const RolesModal: React.FC<RolesModalProps> = ({
  visible,
  edit,
  onCancel,
  onOk,
  data,
}) => {
  const [formRef] = Form.useForm<Roles>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState<DataType[]>([]);

  const preparePermissions = (data: Permission[]): DataType[] => {
    const prepare: DataType[] = [];
    let count = 0;

    for (const row of data) {
      prepare.push({
        key: `${count++}`,
        actions: row.actions,
        subject: row.subject,
      });
    }

    return prepare;
  };

  useEffect(() => {
    if (edit && data) {
      formRef.setFieldsValue(data);
      if (data?.permissions) {
        const result = preparePermissions(data?.permissions as unknown as Permission[]);
        setPermissions(result);
      }
    }
  }, [edit, data, formRef]);

  const handleOnSubmit = async (values: Roles) => {
    setLoading(true);
    values.permissions = permissions.map((item) => {
      const newItem: Permission = {
        actions: item.actions,
        subject: item.subject,
      };

      return newItem;
    });

    const response = data?._id
      ? await APIService.updateRoles(data?._id as string, values)
      : await APIService.createRoles(values);
    if (response.ok) {
      if (onOk) {
        onOk();
      }
      setPermissions([]);
      formRef.resetFields();
      if (edit) {
        message.success(t('roles.updateSuccess'));
      } else {
        message.success(t('roles.createSuccess'));
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      centered
      open={visible}
      title={edit ? t('roles.edit') : t('roles.create')}
      onOk={() => formRef.submit()}
      okText={edit ? t('general.update') : t('general.create')}
      width={800}
      onCancel={() => {
        onCancel?.();
        setPermissions([]);
        formRef.resetFields();
      }}
      okButtonProps={{ loading }}
    >
      <RoleForm formRef={formRef} onSubmit={handleOnSubmit} />
      <PermissionsForm data={permissions} setData={setPermissions} />
    </Modal>
  );
};
export default RolesModal;
